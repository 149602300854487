import type { ProviderResponse } from "@9amhealth/openapi";
import { NineInput } from "@9amhealth/wcl";
import type { NineSubmitEvent } from "@9amhealth/wcl/generated/events/events";
import {
  NineActions,
  NineButton,
  NineHeading
} from "@9amhealth/wcl/generated/react";
import { IonButton, IonModal, IonSearchbar } from "@ionic/react";
import clsx from "clsx";
import type { FC } from "react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  IconChevronRight,
  IconCloseCircle,
  IconCrossXCloseMissed,
  IconEmail,
  IconFax,
  IconFilterHorizontalSliders,
  IconMap,
  IconPersonPhysicianProfileStethoscope,
  IconPhone
} from "src/constants/icons";
import { formatPhoneNumberNational } from "src/lib/formatPhoneNumber";
import reportErrorSentry from "src/lib/reportErrorSentry";
import toTitleCase from "src/lib/toTitleCase";
import translate from "src/lib/translate";
import type { PCPSearchFormValues } from "src/state/PatientPCPBloc/PatientPCPBloc";
import PatientPCPBloc from "src/state/PatientPCPBloc/PatientPCPBloc";
import { useBloc } from "src/state/state";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import OnEvent from "src/ui/components/OnEvent/OnEvent";
import {
  ClearButton,
  FilterInputWrapper,
  FilterWrapper,
  HelpTextWrapper,
  InfoWrapper,
  ResultsNumberWrapper,
  SearchResultWrapper
} from "src/ui/components/PharmacyForm/PharmacyForm";
import Translate from "src/ui/components/Translate/Translate";
import { AppQueryPopupsController } from "../AppQueryPopups/AppQueryPopupsBloc";

export enum AddPCPInfoAction {
  SELECT_PCP = "select-pcp"
}

const PCPForm: FC<{ onPCPSaved?: () => void; onSkip?: () => void }> = ({
  onPCPSaved,
  onSkip
}) => {
  const [
    { loading, pcp, pcpSearchResults, pcpSearchError },
    { temporaryPCPSearchFormValues, searchPCPs, savePCP, clearPCPSearchResults }
  ] = useBloc(PatientPCPBloc);
  const [filterInputVisible, setFilterInputVisible] = useState(false);
  const [searchButtonVisible, setSearchButtonVisible] = useState(true);
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(true);
  const [forceLastNameError, setForceLastNameError] = useState("");
  const [filteredPCPSearchResults, setFilteredPCPSearchResults] =
    useState<ProviderResponse[]>();
  const [selectedPCP, setSelectedPCP] = useState<ProviderResponse>();
  const [selectedPCPIndex, setSelectedPCPIndex] = useState<number>();

  const inputRef = useRef<NineInput>(null);
  const pcpInfoModalRef = useRef<HTMLIonModalElement>(null);

  const showSkip = useSearchParams()[0].get("pcpShowSkip") === "true";

  useEffect(() => {
    if (pcp) {
      temporaryPCPSearchFormValues.current = {
        lastName: pcp.provider.lastName
      };
      void searchPCPs(temporaryPCPSearchFormValues.current);
      setSearchButtonVisible(false);
    }
  }, []);

  useEffect(() => {
    setForceLastNameError(pcpSearchError ?? "");
  }, [pcpSearchError]);

  const pcpValue = useMemo(() => {
    if (pcp) {
      setSearchButtonDisabled(false);
      return pcp.provider.lastName;
    }

    return temporaryPCPSearchFormValues.current?.lastName ?? "";
  }, [pcp]);

  const selectedPCPPhoneNumber = useMemo(
    () => formatPhoneNumberNational(selectedPCP?.provider.phone, false),
    [selectedPCP]
  );

  const selectedPCPFaxNumber = useMemo(
    () => formatPhoneNumberNational(selectedPCP?.provider.fax, false),
    [selectedPCP]
  );

  const dismissPCPInfoModal = () => {
    void pcpInfoModalRef.current?.dismiss();
    AppQueryPopupsController.closePopup();
  };

  const handleSearchPCPs = (e: NineSubmitEvent<PCPSearchFormValues>) => {
    const input = inputRef.current;

    if (!input?.value.trim().length) {
      setSearchButtonDisabled(true);
      setForceLastNameError(translate("invalid_last_name"));
      return;
    }

    temporaryPCPSearchFormValues.current = e.detail;
    void searchPCPs(temporaryPCPSearchFormValues.current)
      .then(() => {
        setSearchButtonVisible(false);
        input.querySelector("input")?.blur();
      })
      .catch(() => {
        setSearchButtonDisabled(true);
      });
  };

  const handleInputChange = () => {
    setForceLastNameError("");
    setSearchButtonDisabled(false);
    setSearchButtonVisible(true);
    setFilteredPCPSearchResults(undefined);
    clearPCPSearchResults();
  };

  const parsePCPAddress = (pcpSearchResult: ProviderResponse) => {
    const addressString = `${pcpSearchResult.provider.providerAddress?.street}${
      pcpSearchResult.provider.providerAddress?.aptSuite
        ? `, ${pcpSearchResult.provider.providerAddress.aptSuite}`
        : ""
    }, ${pcpSearchResult.provider.providerAddress?.city}`;

    return `${toTitleCase(addressString)}, ${
      pcpSearchResult.provider.providerAddress?.state
    } ${pcpSearchResult.provider.providerAddress?.zip}, USA`;
  };

  const displaySearchResultsNumber = () => {
    if (!pcpSearchResults) {
      return "";
    }

    const results = filteredPCPSearchResults ?? pcpSearchResults;
    const resultsLength = results.length;

    return <Translate msg="pcpResult" variables={{ count: resultsLength }} />;
  };

  const handleFilterButtonClick = () => {
    setFilteredPCPSearchResults(undefined);
    setFilterInputVisible(!filterInputVisible);
  };

  const handleSearchResultsFilter = (e: Event) => {
    const filterValue = (e.target as HTMLInputElement).value.toLowerCase();

    if (!filterValue.length) {
      setFilteredPCPSearchResults(undefined);
      return;
    }

    const filteredResults = pcpSearchResults?.filter((pcpSearchResult) =>
      [
        pcpSearchResult.provider.firstName,
        pcpSearchResult.provider.providerAddress?.street,
        pcpSearchResult.provider.providerAddress?.aptSuite,
        pcpSearchResult.provider.providerAddress?.zip,
        pcpSearchResult.provider.providerAddress?.city
      ]
        .join(", ")
        .toLowerCase()
        .includes(filterValue)
    );

    setFilteredPCPSearchResults(filteredResults);
  };

  const handlePCPSearchResultClick = (
    pcpSearchResult: ProviderResponse,
    i: number
  ) => {
    setSelectedPCP(pcpSearchResult);
    setSelectedPCPIndex(i);
  };

  const handlePCPConfirmationModalDismiss = () => {
    setSelectedPCP(undefined);
    setSelectedPCPIndex(undefined);
  };

  const handleConfirmPCPSelection = () => {
    if (!selectedPCP) {
      return;
    }

    BlockingLoadingOverlayController.startLoading();

    void savePCP(selectedPCP)
      .then(() => {
        BlockingLoadingOverlayController.loadingSuccess();
        onPCPSaved?.();
        temporaryPCPSearchFormValues.current = null;
      })
      .catch((e: unknown) => {
        reportErrorSentry(e);
        BlockingLoadingOverlayController.loadingError({
          retry: () => {
            handleConfirmPCPSelection();
          },
          onCancel: () => {
            dismissPCPInfoModal();
          }
        });
      });
  };

  // TODO: Fix the logic of clearing the input - focus, blur, focus should be replaced
  const clearInput = () => {
    const inputHTMLElement = inputRef.current?.querySelector("input");

    if (inputHTMLElement) {
      inputRef.current?.setValue("");
      // re-focus input html element in order for mask to work properly
      inputHTMLElement.focus();
      inputHTMLElement.blur();
      inputHTMLElement.focus();
    }
  };

  return (
    <div style={{ padding: "0 0.6rem" }}>
      <NineHeading>
        <h3 className="as-h4-large">
          <Translate msg="pcp.select" />
        </h3>
        <nine-spacer s="xs"></nine-spacer>
      </NineHeading>
      <nine-spacer s="lg"></nine-spacer>

      <OnEvent
        events={{
          submit: handleSearchPCPs,
          [NineInput.customEvents.change]: handleInputChange
        }}
      >
        <nine-form>
          <nine-input
            name="lastName"
            label={translate("lastName")}
            required="true"
            force-error={forceLastNameError}
            value={pcpValue}
            ref={inputRef}
            help={translate("pcp.lastName.help")}
          >
            <ClearButton slot="decoration-end" onClick={clearInput}>
              <IconCrossXCloseMissed />
            </ClearButton>
          </nine-input>

          {searchButtonVisible && (
            <>
              <NineActions style={{ justifyContent: "center" }}>
                <NineButton disabled={`${searchButtonDisabled}`} type="submit">
                  <Translate msg="search" />
                </NineButton>
              </NineActions>
              {onSkip && showSkip && (
                <nine-center>
                  <nine-spacer s="md"></nine-spacer>
                  <p className="m0 color-c-80">
                    {translate("task.skip.question", {
                      context: "select_pcp"
                    })}
                  </p>
                  <nine-spacer s="xxxs"></nine-spacer>
                  <ClearButton
                    style={{ display: "block", margin: "0 auto" }}
                    onClick={onSkip}
                  >
                    {translate("task.skip.button_text")}
                  </ClearButton>
                </nine-center>
              )}
            </>
          )}
        </nine-form>
      </OnEvent>
      <nine-spacer s="sm"></nine-spacer>

      <AsyncContent height="20rem" check={[loading === false]}>
        {pcpSearchResults && (
          <>
            <ResultsNumberWrapper>
              <small className="m0 strong">
                {displaySearchResultsNumber()}
              </small>
              <FilterWrapper
                onClick={handleFilterButtonClick}
                className={clsx({
                  active: filterInputVisible
                })}
              >
                <IconFilterHorizontalSliders />
                <small className="m0 strong">
                  <Translate msg="filter" />
                </small>
              </FilterWrapper>
            </ResultsNumberWrapper>

            {filterInputVisible && (
              <FilterInputWrapper>
                <IonSearchbar
                  autocapitalize="off"
                  className="filter-search-results"
                  placeholder={translate("filter.address.help")}
                  onIonInput={handleSearchResultsFilter}
                />
              </FilterInputWrapper>
            )}

            {(filteredPCPSearchResults ?? pcpSearchResults).map(
              (pcpSearchResult, i) => (
                <SearchResultWrapper
                  className={clsx({
                    active: i === selectedPCPIndex
                  })}
                  key={`${pcpSearchResult.provider.npi}-${i}`}
                  onClick={() => handlePCPSearchResultClick(pcpSearchResult, i)}
                >
                  <IconPersonPhysicianProfileStethoscope />

                  <div style={{ width: "100%" }}>
                    <p className="m0 strong">
                      <Translate
                        msg="pcp.fullTitle"
                        variables={{
                          firstName: pcpSearchResult.provider.firstName,
                          lastName: pcpSearchResult.provider.lastName
                        }}
                      />
                    </p>
                    <nine-spacer s="xxxs"></nine-spacer>
                    <small className="color-c-80">
                      {pcpSearchResult.taxonomy}
                    </small>
                    <br />
                    <small className="color-c-80">
                      {parsePCPAddress(pcpSearchResult)}
                    </small>
                  </div>

                  <div style={{ alignSelf: "center" }}>
                    <IconChevronRight />
                  </div>
                </SearchResultWrapper>
              )
            )}

            <HelpTextWrapper>
              <div className="m0 lauf-text">
                <p className="m0 strong">
                  <Translate msg="pcp.notFound.question" />
                </p>
                <nine-spacer s="xs"></nine-spacer>
                <small>
                  <Translate msg="pcp.notFound.help" />
                </small>
                <nine-spacer s="xs"></nine-spacer>
                <ul className="m0">
                  <li className="m0">
                    <small>{<Translate msg="fullName" />}</small>
                  </li>
                  <li className="m0">
                    <small>
                      <Translate msg="fullAddress" />
                    </small>
                  </li>
                  <li className="m0">
                    <small>
                      <Translate msg="phoneNumber" />
                    </small>
                  </li>
                  <li className="m0">
                    <small>
                      <Translate msg="faxNumberOptional" />
                    </small>
                  </li>
                  <li className="m0">
                    <small>
                      <Translate msg="emailOptional" />
                    </small>
                  </li>
                </ul>
              </div>
            </HelpTextWrapper>
          </>
        )}
      </AsyncContent>

      <IonModal
        isOpen={Boolean(selectedPCP)}
        onDidDismiss={handlePCPConfirmationModalDismiss}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.25, 0.5, 0.75]}
        className="selected-search-result-info"
        ref={pcpInfoModalRef}
      >
        <div>
          <IonButton
            aria-label="Close"
            className="close-modal-button"
            onClick={handlePCPConfirmationModalDismiss}
          >
            <IconCloseCircle />
          </IonButton>

          <nine-spacer s="md"></nine-spacer>
          <p className="m0 strong as-subhead">
            Dr. {selectedPCP?.provider.firstName}{" "}
            {selectedPCP?.provider.lastName}
          </p>

          {selectedPCP?.taxonomy && (
            <>
              <nine-spacer s="xxs"></nine-spacer>
              <p className="m0">{selectedPCP.taxonomy}</p>
            </>
          )}

          <nine-spacer s="sm"></nine-spacer>
          {selectedPCP && (
            <InfoWrapper>
              <div style={{ display: "flex" }}>
                <IconMap />
              </div>
              <p className="m0 color-c-80">{parsePCPAddress(selectedPCP)}</p>
            </InfoWrapper>
          )}

          {selectedPCPPhoneNumber && (
            <InfoWrapper>
              <div style={{ display: "flex" }}>
                <IconPhone />
              </div>
              <p className="m0 color-c-80">
                <a href={`tel:${selectedPCP?.provider.phone}`}>
                  {selectedPCPPhoneNumber}
                </a>
              </p>
            </InfoWrapper>
          )}

          {selectedPCPFaxNumber && (
            <InfoWrapper>
              <div style={{ display: "flex" }}>
                <IconFax />
              </div>
              <p className="m0 color-c-80">{selectedPCPFaxNumber}</p>
            </InfoWrapper>
          )}

          {selectedPCP?.provider.email && (
            <InfoWrapper>
              <div style={{ display: "flex" }}>
                <IconEmail />
              </div>
              <p className="m0 color-c-80">
                <a href={`mailto:${selectedPCP.provider.email}`}>
                  {selectedPCP.provider.email}
                </a>
              </p>
            </InfoWrapper>
          )}

          <nine-spacer s="md"></nine-spacer>
          <NineActions style={{ justifyContent: "center" }}>
            <NineButton onClick={handleConfirmPCPSelection}>
              <Translate msg="confirm" />
            </NineButton>
          </NineActions>
        </div>
      </IonModal>
    </div>
  );
};

export default PCPForm;
